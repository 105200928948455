import * as React from 'react';
import { darken, lighten } from '@danfoss/etui-colors';
import { EmptyState, IconGlyph } from '@danfoss/etui-core';
import { useTheme, StyleProps } from '@danfoss/etui-system';

export const EmptyPageContent = React.memo(
  ({
    title,
    description,
    glyph,
    style,
  }: {
    title: string;
    description?: string | React.ReactNode;
    glyph: IconGlyph;
    style?: {
      icon: StyleProps;
    };
  }) => {
    const theme = useTheme();
    return (
      <EmptyState
        testId="emptyPage-content-emptyState"
        styles={{
          root: {
            height: `100%`,
            color: theme.palette.grey[40],
            bg: darken(theme.palette.common.bg, 0.01),
          },
        }}
      >
        <EmptyState.Icon
          // @ts-ignore
          size={256}
          glyph={glyph}
          styles={{
            root: {
              mt: style?.icon ? style.icon.mt : -112,
              color: lighten(theme.palette.grey[40] as string, 0.25),
            },
          }}
        />
        <EmptyState.Title color={theme.palette.grey[60]}>
          {title}
        </EmptyState.Title>
        {description && (
          <EmptyState.SubTitle color={theme.palette.grey[60]}>
            {description}
          </EmptyState.SubTitle>
        )}
      </EmptyState>
    );
  },
);
