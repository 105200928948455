export enum TABLE_ADDRESS {
  AUDIT_TRAIL = '20105-5',
  COMPRESSOR_STAGING_PATTERN = '20059-0',
  CONFIGURATION = '20091',
  CONTROL_OVERRIDE = '20092',
  HISTORY_SENSORS = '20001-3',
  INFO = '20105',
  INFO_CALCULATIONS = '20105-1',
  MISC_CALCULATIONS = '20081-5',
  NETWORK_NODES = '20079',
  NETWORK_NODES_DOWNLOAD_PAGE = '20079-7',
  NETWORK_NODES_UPLOAD = '20079',
  NETWORK_POINTS_RELAY = '20071-0',
  NODE_OVERVIEW = '20079-0',
  QUICKSETUP = '20091-0',
  SECURITY_TAB = '20091-4',
  SETTINGS_TAB = '20018-1',
  STATUS_ALARMS = '20027',
  STATUS_HVAC = '20022-0',
  STATUS_TAB = '20018-0',
  SYSTEM_USERS = '20085-2',
  UNIT_INFO = '20105-0',
  ALARM_RELAYS = '20030',
  CONSUMPTION = '20025-3',
  HOME_SCREEN = '20085',
  LEAK = '20084-4',
  HISTORY_ARCHIVE = '20034',
  MCX = '20108',
  CONDENSER = '20053',
}

export enum FOLDER_NAME {
  HISTORY_ARCHIVE = 'HistoryArchive',
}

export enum OLD_CONFIG_TYPE {
  MCX = '29',
}
